import React from "react"
import HeroImage from "./components/HeroImage"

export default function Quote() {
  return (
    <section className="section-quote">
      <div className="container">
        <div className="columns is-centered is-mobile">
          <div className="column">
            <h1>&ldquo;</h1>
            <div className="columns is-centered">
              <div className="column is-three-fifths">
                <h3>Una vera e propria 'distruption', verso una democratizzazione della materia,
                che la sottrae all'empireo di consulenti-azzeccagarbugli che a volte fanno dell'astruseria
                  del linguaggio una cortina fumogena per impressionare il cliente</h3>
                <p className="mt-4"><b>Emanuele Orlando</b></p>
                <p>Direttore Business Insider Italia</p>
                <p><HeroImage fileName="bii.png" /></p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}
